@media (prefers-color-scheme: dark) {
  :root {
    --body: #19181c;
    --light: #f5f5f5;
    --border: #383841;
    --border-radius: 4px;
    --text-primary: #808093;
    --hover: #2e2e34;
    --online: #94e864;
    --offline: #999;
    --red: #d32f2f;
    --theme-primary: #d1d2d3;
    --link: #007bff;
    --card-body: #201f23;
    --input-box-background: #201f23;
    --input-box-color: #808093;
    --input-disabled: #19181c;
    --input-focus-border: #50505e;
    --invert-color: invert(1);
    --scrollbar-bg: #201f23;
    --scrollbar-thumb: #2b292e;
    --avatar-border: #6f6b77;
    --msg-preview: #47474e;
    --highlight-text-color: rgb(252, 252, 252);
    --highlight-text-bg: #808093;
    --cta-btn-color: #3b4191;
    --cta-btn-hover: #4148a5;
    --button-background: #34333a;
    --sidebar-background: #b1aeac;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --body: #f7f6f3;
    --light: #f5f5f5;
    --border: #ddd;
    --border-radius: 4px;
    --sidebar-background: #f7f6f3;
    --doc-text-primary: #333333;
    --doc-text-secondary: #555555;
    --sidebar-item-hover-background: #e8e7e4;
    --sidebar-item-highlight-background: #fbe49c;
    --sidebar-item-highlight-color: #202124;
    --text-primary: #333333;
    --hover: #f5f5f5;
    --hover-dark: #ccc;
    --online: #94e864;
    --offline: #999;
    --red: #d32f2f;
    --theme-primary: #000;
    --link: #007bff;
    --card-body: #fff;
    --input-box-background: #fff;
    --input-box-color: #495057;
    --input-disabled: #e9ecef;
    --input-focus-border: rgb(145, 145, 145);
    --scrollbar-bg: #fff;
    --scrollbar-thumb: rgb(206, 206, 206);
    --avatar-border: #717171;
    --msg-preview: lightgray;
    --highlight-text-bg: #afd3fa;
    --cta-btn-color: #000;
    --cta-btn-hover: #333;
    --button-background: #333;
  }
}
